import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { LayoutWithBackButton, detectUserErrors } from '@pm/core';
import { Button, Text, useToast } from '@pm/ui';
import { TreatmentType, useStartTreatmentMutation } from '@pm/graphql';
import { PrepTreatmentRouteMap } from '../route-map';

export const RestartPrepPage = () => {
  const { t } = useTranslation('treatments', { keyPrefix: 'PrepTreatment' });
  const navigate = useNavigate();
  const { positive, negative } = useToast();
  const [startTreatment, { loading }] = useStartTreatmentMutation({
    refetchQueries: ['GetPatient'],
  });
  const treatmentManagerRoute = PrepTreatmentRouteMap.treatmentManager.build({
    treatmentType: 'prep',
  });

  const handleClick = async () => {
    try {
      const { data } = await startTreatment({
        variables: { input: { treatmentType: TreatmentType.PrepTreatment } },
      });

      detectUserErrors(data, (response) => response.startTreatment?.errors);

      positive({ content: t('Success') });
      navigate('/');
    } catch (err) {
      negative({ content: t('Errors.CouldNotChange') });
      Sentry.captureException(err);
    }
  };

  return (
    <LayoutWithBackButton
      onBackButtonClick={() => navigate(treatmentManagerRoute)}
    >
      <div className="space-y-xxl">
        <div className="space-y-m">
          <Text size="headingM">{t('ClinicalRecommendations.Title')}</Text>
          <Text size="bodyL">{t('RestartPrep.Body1')}</Text>
          <Text size="bodyL">{t('RestartPrep.Body2')}</Text>
        </div>
        <Button width="full" onClick={handleClick} disabled={loading}>
          {t('RestartPrep.StartTreatment')}
        </Button>
      </div>
    </LayoutWithBackButton>
  );
};
