import { Button, Icon } from '@pm/ui';
import { DoxyPepInfoModal } from '../../../modules/treatments/DoxyPepTreatment/InfoModal/DoxyPepInfoModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DoxyPepInformationModal = () => {
  const { t } = useTranslation('short-questionnaire');
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className="flex flex-col items-center p-4">
      <Button
        intent="ghost"
        leadingIcon={<Icon name="info" />}
        onClick={() => setModalOpen(true)}
      >
        {t('AddOnTreatment.DoxyPEP.ReadMoreButton')}
      </Button>
      <DoxyPepInfoModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
};
