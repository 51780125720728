import { Modal, Text } from '@pm/ui';
import { useTranslation } from 'react-i18next';

type Props = {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
};

export const DoxyPepInfoModal = ({ modalOpen, setModalOpen }: Props) => {
  const { t } = useTranslation('short-questionnaire', {
    keyPrefix: 'AddOnTreatment.DoxyPEP.InfoModal',
  });
  return (
    <Modal.Root open={modalOpen} onOpenChange={setModalOpen}>
      <Modal.Content title={t('Title')}>
        <Modal.Body>
          <div className="space-y-xxxl pb-xxxl">
            <div>
              <Text size="headingS">{t('TakingDoxy.Title')}</Text>
              <div className="pt-m space-y-xs">
                <Text size="bodyL">{t('TakingDoxy.Body1')}</Text>
                <Text size="bodyL">{t('TakingDoxy.Body2')}</Text>
              </div>
            </div>
            <div className="border-b-8" />
            <div>
              <Text size="headingS">{t('SideEffects.Title')}</Text>
              <div className="pt-m space-y-xs">
                <Text size="bodyL">{t('SideEffects.Body1')}</Text>
                <Text size="bodyL">{t('SideEffects.Body2')}</Text>
                <Text size="bodyL">{t('SideEffects.Body3')}</Text>
              </div>
            </div>
            <div className="border-b-8" />
            <div>
              <Text size="headingS">{t('Cost.Title')}</Text>
              <div className="pt-m space-y-xs">
                <Text size="bodyL">{t('Cost.Body1')}</Text>
                <Text size="bodyL">{t('Cost.Body2')}</Text>
                <Text size="bodyL">{t('Cost.Body3')}</Text>
              </div>
            </div>
            <div className="border-b-8" />
            <div>
              <Text size="headingS">{t('GettingDoxyPEP.Title')}</Text>
              <div className="pt-m space-y-xs">
                <Text size="bodyL">{t('GettingDoxyPEP.Body1')}</Text>
                <Text size="bodyL">{t('GettingDoxyPEP.Body2')}</Text>
                <Text size="bodyL">{t('GettingDoxyPEP.Body3')}</Text>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
