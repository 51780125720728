import { DoxyPepInformationModal } from './components/DoxyPepInfoModal';

// If you are looking to add another component to the mapping, you can add it here.
const RESOURCE_MAPPING: Partial<Record<string, () => JSX.Element>> = {
  DoxyPepInfoModal: DoxyPepInformationModal,
};

const QuestionnaireResourceComponentMapper = ({ value }: { value: string }) => {
  const Component = RESOURCE_MAPPING[value as keyof typeof RESOURCE_MAPPING];
  if (!Component) {
    return null;
  }

  return <Component />;
};

export default QuestionnaireResourceComponentMapper;
