import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import * as Sentry from '@sentry/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ButtonLink, Checkbox, Text, useToast } from '@pm/ui';
import {
  LayoutWithBackButton,
  detectUserErrors,
  toServerError,
} from '@pm/core';
import { TreatmentType, usePauseTreatmentMutation } from '@pm/graphql';
import { PrepTreatmentRouteMap } from '../route-map';
import { FormSubmitButton } from '@pm/forms';

export const PausePrepPage = () => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'PrepTreatment',
  });
  const navigate = useNavigate();
  const { positive, negative } = useToast();
  const [pauseTreatment, { loading }] = usePauseTreatmentMutation();

  const schema = z.object({
    risksAccepted: z.literal<boolean>(true, {
      errorMap: () => ({
        message: 'You must agree to the terms to pause your treatment.',
      }),
    }),
    canContact: z.boolean().optional(),
  });

  const methods = useForm({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: { risksAccepted: false, canContact: false },
  });

  const onSubmit = methods.handleSubmit(
    async ({ risksAccepted, canContact }) => {
      try {
        const { data } = await pauseTreatment({
          variables: {
            input: {
              treatmentType: TreatmentType.PrepTreatment,
              consentToPause: risksAccepted,
              consentToFeedback: canContact,
            },
          },
        });

        detectUserErrors(data, (res) => res.pauseTreatment?.errors);

        positive({ content: t('Success') });
        navigate('/');
      } catch (err) {
        negative({
          content: t('Errors.CouldNotChange'),
        });
        Sentry.captureException(err);
        methods.setError(...toServerError(t('Errors.CouldNotChange')));
      }
    },
  );

  return (
    <LayoutWithBackButton
      onBackButtonClick={() =>
        navigate(
          PrepTreatmentRouteMap.clinicalRecommendations.build({
            treatmentType: 'prep',
            action: 'pause',
          }),
        )
      }
    >
      <div className="space-y-s">
        <Text size="headingS">{t('PausePrepRisks.Title')}</Text>
        <Text size="bodyL">{t('PausePrepRisks.Description')}</Text>
        <div className="space-y-xxl">
          <FormProvider {...methods}>
            <form onSubmit={onSubmit} className="space-y-s">
              <ul className="ml-6 list-disc">
                <li>
                  <Text size="bodyL">{t('PausePrepRisks.ListItem1')}</Text>
                </li>
                <li>
                  <Text size="bodyL">{t('PausePrepRisks.ListItem2')}</Text>
                </li>
                <li>
                  <Text size="bodyL">{t('PausePrepRisks.ListItem3')}</Text>
                </li>
                <li>
                  <Text size="bodyL">{t('PausePrepRisks.ListItem4')}</Text>
                </li>
              </ul>
              <Controller
                control={methods.control}
                name="risksAccepted"
                render={({ field: { onChange, name } }) => (
                  <Checkbox
                    name={name}
                    onChange={onChange}
                    label={t('PausePrepRisks.CheckboxText1')}
                  />
                )}
              />
              {methods.formState.errors.risksAccepted && (
                <p className="text-negative text-label">
                  {methods.formState.errors.risksAccepted.message}
                </p>
              )}
              <Controller
                control={methods.control}
                name="canContact"
                render={({ field: { onChange, name } }) => (
                  <Checkbox
                    name={name}
                    onChange={onChange}
                    label={t('PausePrepRisks.CheckboxText2')}
                  />
                )}
              />
              <div className="space-y-m">
                <FormSubmitButton width="full" disabled={loading}>
                  {t('PausePrepRisks.PauseTreatment')}
                </FormSubmitButton>
                <ButtonLink
                  to={PrepTreatmentRouteMap.treatmentManager.build({
                    treatmentType: 'prep',
                  })}
                  intent="secondary"
                  width="full"
                >
                  {t('PausePrepRisks.Decline')}
                </ButtonLink>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </LayoutWithBackButton>
  );
};
